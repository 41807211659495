import { DEFAULT_OFFSET, defaultRequestFilters } from '../constants/constants'
import {
  isTransactionTypesValid,
  isValidAddress,
  isValidAmm,
  isValidDate,
  isValidNetwork,
  isValidOrder,
  isValidSortBy,
  isValidTokenId,
  isValidTokenStatus,
  isValidTradeSize,
  isValidTradeSizeStr,
  isValidWalletCategory,
} from '../helpers/validators'
import {
  ApiResponseList,
  ErrorsValidation,
  FilterOptions,
  TokenStatuses,
  TransactionModel,
  TransactionTypes,
} from '../model'
import apiClient from './ApiClient'

export const fetchTransactionsV3 = async ({
  filters,
  useLastEndpoint,
}: {
  filters: FilterOptions
  useLastEndpoint?: boolean
}): Promise<ApiResponseList<TransactionModel>> => {
  const emptyResponseWithError = {
    response: { data: [], total: 0 },
    isAborted: false,
    isError: true,
  }

  if (filters.current_token_id && !isValidTokenId(filters.current_token_id)) {
    return { ...emptyResponseWithError, errorMessage: ErrorsValidation.invalidTokenId }
  }
  const options: Record<string, unknown> = {
    sort_by: isValidSortBy(filters.sort_by) ? filters.sort_by : defaultRequestFilters.sort_by,
    limit:
      Number.isInteger(filters.limit) && (filters.limit as number) > 0
        ? filters.limit
        : defaultRequestFilters.limit,
    offset:
      Number.isInteger(filters.offset) && (filters.offset as number) > 0
        ? filters.offset
        : DEFAULT_OFFSET,
    order: isValidOrder(filters.order) ? filters.order : defaultRequestFilters.order,
    with_full_totals: filters.with_full_totals || defaultRequestFilters.with_full_totals,
  }

  if (isValidAmm(filters.amm) && filters.amm !== 'all') {
    options.amm = filters.amm
  }

  if (isValidAddress(filters.account)) {
    options.account = filters.account
  }
  if (isValidAddress(filters.second_account)) {
    options.second_account = filters.second_account
  }

  if (isValidAddress(filters.pool_address)) {
    options.pool_address = filters.pool_address
  }

  if (isTransactionTypesValid(filters.transaction_types)) {
    options.transaction_types = filters.transaction_types
  }

  if (
    isValidTokenStatus(filters.token_status) &&
    (Array.isArray(
      filters.transaction_types && filters.transaction_types[0] !== TransactionTypes.swap
    ) ||
      [TokenStatuses.sell, TokenStatuses.buy].includes(filters.token_status as TokenStatuses))
  ) {
    options.token_status = filters.token_status
  } else {
    options.token_status = TokenStatuses.all
  }

  if (isValidDate(filters.date)) {
    options.date = filters.date
  }

  if (isValidTradeSize(filters.trade_size_usd) || isValidTradeSizeStr(filters.trade_size_usd)) {
    options.trade_size_usd = filters.trade_size_usd
  }

  if (isValidWalletCategory(filters.wallet_category)) {
    options.wallet_category = filters.wallet_category
  }

  if (isValidTokenId(filters.token_id)) {
    options.token_id = filters.token_id
  }

  if (isValidTokenId(filters.current_token_id)) {
    options.current_token_id = filters.current_token_id
  }

  if (isValidNetwork(filters.network)) {
    options.network = filters.network
  }

  const { error, responseData } = await apiClient.txs.load({ useLastEndpoint, filters: options })

  if (error) {
    console.error(`fetching transactions error:`, error)
    return {
      ...emptyResponseWithError,
      errorMessage: error.message,
    } as ApiResponseList<TransactionModel>
  }

  return {
    response: { data: responseData?.data, total: responseData?.total || 0 },
  }
}

export const isWithinTheLastWeek = (trade: TransactionModel): boolean => {
  return new Date().getTime() / 1000 - trade.timestamp < 60 * 60 * 24 * 7
}

export const mapObjectToFilterOptions = (object: FilterOptions): FilterOptions => ({
  current_token_id: object.current_token_id,
  transaction_types: object.transaction_types,
  amm: object.amm,
  network: object.network,
  trade_size_usd: object.trade_size_usd,
  token_id: object.token_id,
  account: object.account,
  second_account: object.second_account,
  pool_address: object.pool_address,
  date: object.date,
  sort_by: object.sort_by,
  order: object.order,
  limit: object.limit,
  offset: object.offset,
  with_full_totals: object.with_full_totals,
  transfer_from_address: object.transfer_from_address,
  transfer_to_address: object.transfer_to_address,
  token_status: object.token_status,
  wallet_category: object.wallet_category,
})
