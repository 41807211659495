import { getSoundsDisabledFromLocalStorage } from './preferencesService'

let walletConnectSound: HTMLAudioElement | undefined
let favoriteSound: HTMLAudioElement | undefined
let unfavoriteTokenSound: HTMLAudioElement | undefined
let swapSubmittedSound: HTMLAudioElement | undefined
let swapConfirmedSound: HTMLAudioElement | undefined
let swapFailedSound: HTMLAudioElement | undefined
let soundToggleSound: HTMLAudioElement | undefined

export const playSoundToggleSound = async (): Promise<void> => {
  if (!soundToggleSound) {
    soundToggleSound = new Audio('/sounds/sound-effect-toggle.mp3')
  }

  play(soundToggleSound)
}

export const playWalletConnectSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!walletConnectSound) {
    walletConnectSound = new Audio('/sounds/connecting-wallet.mp3')
  }

  play(walletConnectSound)
}

export const playFavoriteSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!favoriteSound) {
    favoriteSound = new Audio('/sounds/favoriting-token.mp3')
  }

  play(favoriteSound)
}

export const playUnfavoriteSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!unfavoriteTokenSound) {
    unfavoriteTokenSound = new Audio('/sounds/unfavoriting-token.mp3')
  }

  play(unfavoriteTokenSound)
}

export const playSubmitTxnSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!swapSubmittedSound) {
    swapSubmittedSound = new Audio('/sounds/swap-submitted.mp3')
  }

  play(swapSubmittedSound)
}

export const playConfirmedTxnSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!swapConfirmedSound) {
    swapConfirmedSound = new Audio('/sounds/swap-successful.mp3')
  }

  play(swapConfirmedSound)
}

export const playFailedTxnSound = async (isMobile: boolean): Promise<void> => {
  if (isMobile || getSoundsDisabledFromLocalStorage()) {
    return
  }

  if (!swapFailedSound) {
    swapFailedSound = new Audio('/sounds/swap-failed.mp3')
  }

  play(swapFailedSound)
}

const play = async (audio?: HTMLAudioElement): Promise<void> => {
  if (audio) {
    audio.play()
  }
}
